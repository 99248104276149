import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionStorageService } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { EventManagerService } from "src/app/common/service/event-manager.service";
import { Constant } from "src/app/common/constant";
import { DialogService } from "src/app/common/service/dialog.service";
import { AppQueries } from "../../../../../state";
import { ComprehensivePlanService } from "../../comprehensive-plan.service";
import { ObjectUtil } from "../../../../../common/utils/object.util";
import { NormalPoint } from "../../../share-plan/components/input-value-start-end/normal.point.model";
import { InputValueStartEndComponent } from "../../../share-plan/components/input-value-start-end/input-value-start-end.component";
import { FormEditCheckChangeComponent } from "../../../../../common/extend-code/form.edit.check.change.component";

@Component({
  selector: "tab-config-asset-annual-growth",
  styleUrls: ["./tab-config-asset-annual-growth.component.scss"],
  templateUrl: "./tab-config-asset-annual-growth.component.html",
})
export class TabConfigAssetsAnnualGrowthComponent
  extends FormEditCheckChangeComponent
  implements OnInit
{
  @ViewChild(InputValueStartEndComponent)
  inputValueStartEndComponent: InputValueStartEndComponent;
  public Constant = Constant;
  subscriptions?: Subscription[] = [];
  @Input() config;
  @Input() assetsNeedInput: any;
  listConfigs: any[] = [];
  list: any[] = [];
  assets: any[] = [];
  selectedAsset: any;
  nextAsset: any;
  updateTime: any;
  typeId: any;
  configChild: any;
  constructor(
    public eventManager: EventManagerService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public cdf: ChangeDetectorRef,
    private appQueries: AppQueries,
    private comprehensivePlanService: ComprehensivePlanService,
    public sessionStorageService: SessionStorageService
  ) {
    super(eventManager);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isShowWarningChange = false;
    this.subscriptions = [
      this.appQueries.resourcePlanner$.subscribe((sourceInfos) => {
        if (!sourceInfos) {
          return;
        }
        this.assetsNeedInput = this.assetsNeedInput || {};
        this.assets = JSON.parse(JSON.stringify(sourceInfos.assetType || []));
        this.subscribeState.next();
      }),
      this.eventManager.subscribe("save-config-asset-annual-growth", (res) => {
        if (!res.content.assetId) {
          return;
        }
        this.assets.forEach((asset) => {
          asset.child.forEach((item) => {
            if (item.id === res.content.assetId) {
              item.backgroundColor = "white";
            }
          });
        });
      }),
    ];
    this.parentId = "config-annual";
    this.emitComponent();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      setTimeout(() => {
        if (this.subscribeState.isComplete) {
          this.parseData();
        } else {
          this.subscribeState.next();
        }
      }, 200);
    }
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
  }
  parseData(): void {
    this.config = this.config || {};
    this.typeId = this.config.typeId;
    let assets = [];
    this.assets.forEach((asset) => {
      assets = [...assets, ...(asset.child || [])];
    });
    let assetObject = ObjectUtil.listToObjByField(assets, "id");
    this.config.configByType = this.config.configByType || {};
    this.listConfigs = Object.keys(this.config.configByType).map((key) => {
      const config = this.config.configByType[key];
      config.configs = config.configs || [];
      return {
        backgroundColor: this.assetsNeedInput[key] ? "rgb(255, 250, 222)" : "white",
        assetId: key,
        assetName: assetObject[key],
        list: config.configs,
        updateTime: config.updateAt,
      };
    });
    let isMoved = false;
    this.assets.forEach((asset, index) => {
      asset.child.forEach((child) => {
        child.isExistData = this.config.configByType[child.id] ? true : false;
        child.isNeedInput = this.assetsNeedInput[child.id] ? true : false;
        if (this.assetsNeedInput && child.isNeedInput && !isMoved) {
          isMoved = true;
          this.chooseAsset(child);
        }
      });
    });
    if (!this.selectedAsset) {
      this.chooseAsset(assetObject[1]);
    }
  }
  chooseAsset(asset) {
    this.selectedAsset = asset;
    this.list = (this.listConfigs || []).filter((item) => {
      return item.assetId === this.selectedAsset.id;
    });
    this.configChild = {
      typeId: this.config.typeId,
      typeCode: this.config.typeCode,
      list: [],
      assetId: null,
      updateTime: null,
    };
    const findConfig = this.listConfigs.find(
      (item) => item.assetId == this.selectedAsset.id
    );
    if (!findConfig) {
      return;
    }
    (this.configChild.updateTime = findConfig.updateTime),
      (this.configChild.assetId = findConfig.assetId),
      (this.configChild.list = (findConfig.list || []).map((item) => {
        return new NormalPoint({
          startPoint: item.beginYear,
          endPoint: item.endYear,
          startOperatorId: item.beginRelationalOperatorId,
          endOperatorId: item.endRelationalOperatorId,
          value: item.growthRate,
        });
      }));
  }
  checkChangeConfig(asset) {
    this.nextAsset = asset;
    this.inputValueStartEndComponent.confirmChangeRouter(true).then((res) => {
      if (res) {
        this.chooseAsset(this.nextAsset);
      } else {
        this.showWarning();
      }
    });
  }
  checkChangeRouter() {
    return !this.inputValueStartEndComponent.isChanged;
  }
  showWarning(message?, title?) {
    super.showWarning(message, title);
    this.eventManager.broadcast({
      name: "show-backdrop-dialog",
    });
  }
  funcWarning() {
    this.chooseAsset(this.nextAsset);
  }
}
